import React, {useState} from "react";
import classes from './ApplicationLongTermFinal4.module.css';
import ApplicationModal from "../../ApplicationModal/ApplicationModal";


function ApplicationLongTermFinal4(){

    const [applicationModalIsOpen, setApplicationModalIsOpen] = useState(false);

    function openApplicationModal() {
        setApplicationModalIsOpen(true);
    }

    function closeApplicationModal(){
        setApplicationModalIsOpen(false);
    }




    return(
        <>
            <div className={classes.title}>Consent</div>
            <div className={classes.text}>By electronically signing and submitting this application, you are granting
                Clean SlateZ and it’s representatives the rights to discuss your medical debt with your creditors.
                Your electronic signature will be captured on a consent form that we will share with you creditors as
                needed, authorizing us to speak on your behalf so that we can help resolve your medical debt directly
                with them. </div>
            <div className={classes.text}>You further acknowledge and consent that Clean SlateZ will perform a soft
                inquiry on your credit to confirm the accuracy of information shared by you in this application, and by
                clicking Submit you are providing your authorization.  This soft inquiry will not have any impact on
                your credit score.  However, in the event that the credit report comes back with contradicting
                information from what was provided in the application, we will reach out to you.</div>
            <div className={classes.text}>We will never share or sell your information.  Your info will be stored
                securely internally for the explicit purpose of abolishing your medical debt.</div>
            <div className={classes.text}>
                Submitting an application is not a guarantee of financial remedy for any medical debts, nor does it
                commit Clean SlateZ to taking any action on your behalf.  Clean SlateZ aims to assist all applicants
                with their medical debts, but nothing in this application or process is intended to create a binding
                obligation for Clean SlateZ.</div>
            <div className={classes.checkboxRowBelow}>
                <input className={classes.checkboxInput} type="checkbox" id="haveRead" name="haveRead" value="haveRead"/>
                <label className={classes.checkboxLabel}
                       htmlFor="haveRead"> I have read and understood the above consent and disclosures.</label>
            </div>
            <div className={classes.titlesRow}>
                <div className={classes.titlesRowInside}>PRINTED NAME:
                <span  style={{marginLeft: "5px"}} className={classes.titlesRowInside}> Name Surname</span> </div>
                <div className={classes.titlesRowInside}>SIGNATURE:
                <div className={classes.signature}/></div>
                <div className={classes.titlesRowInside}>DATE:
                    <span style={{marginLeft: "5px"}}
                          className={classes.titlesRowInside}>  10.31.2023</span></div>
            </div>
            <button className={classes.submitBtn}  onClick={()=>openApplicationModal()}>Submit</button>
            <div className={classes.linkstyle}>Click here to review a sample Consent Form.</div>
            <ApplicationModal
                text={"Congratulations on completing the Clean SlateZ application.  Our team will begin analyzing your" +
                    " details within the next 30-days.  Please be on the lookout for an email or text from Clean SlateZ" +
                    " with status updates.  You will be notified as debt is being settled.\n " + "Please regularly" +
                    " check for emails from info@cleanslatez.org  Check your spam folder if you do not see an email" +
                    " confirming receipt of your application within the next 5 minutes."}
                applicationModalIsOpen={applicationModalIsOpen}
                closeApplicationModal={closeApplicationModal}/>
            </>


    )
}

export default ApplicationLongTermFinal4;