import React from "react";
import classes from './Providers1.module.css';
import Image6 from "../../../assets/images/Image6.png";



function Providers1(){
    return(
        <div className={"container"}>
            <div className={classes.main}>
                <div className={classes.left}>
                    <div className={classes.title}>Providers</div>
                    <div className={classes.text}>Join forces with Clean SlateZ to eradicate medical debt.</div>
                    <div className={classes.text}>We know that patient care is of the utmost importance to you and that
                        is your focus. Unfortunately, unpaid medical debt is a reality for all providers, and it takes
                        a tremendous amount of resources and time away from your staff. We will work with you to
                        qualify your delinquent medical debt and provide a fair market value estimation to purchase and
                        abolish these debts.</div>
                    <div className={classes.text}>The positive impact of partnering with Clean SlateZ to your practice
                        and your patients is immeasurable. You will stop spending time trying to collect accounts that
                        people cannot afford to pay, and your patients will have relief that their medical debt has
                        been squared away.</div>
                    <div className={classes.text}>Using a collection agency to chase outstanding debts for patients who
                        cannot afford to pay is burning resources and time that can be better spent. Let us help by
                        parsing out those patients who truly need financial assistance, and then absolving that
                        debt.</div>
                </div>
                <div className={classes.right}>
                    <img className={classes.imgStyle} src={Image6} alt={""}/>
                </div>
            </div>
        </div>
    )
}

export default Providers1;