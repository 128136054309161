import React, {useEffect, useState} from "react";
import Loader from "../../../ui/Loader/Loader";
import Input from "../../../ui/Input/Input";
import classes from './ApplicationLongTermFinal1.module.css';
import {useNavigate} from "react-router-dom";
import SelectSearch from "react-select-search";
import 'react-select-search/style.css';
import axios from "axios";
import config from "../../../config";
import {toast} from "react-toastify";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateField} from '@mui/x-date-pickers/DateField';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";


function ApplicationLongTermFinal1() {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [stateInputData, setStateInputData] = useState([]);
    const [cityInputData, setCityInputData] = useState([]);


    const [body, setBody] = useState({
        first_name: null,
        middle_name:null,
        last_name: null,
        suffix_id: null,
        email: null,
        phone: "",
        address_1: null,
        address_2: null,
        city_id: null,
        state_id: null,
        zip: null,
        ssn: null
    });

    const [errors, setErrors] = useState({
        first_name: null,
        middle_name:null,
        last_name: null,
        suffix_id: null,
        email: null,
        phone: null,
        address_1: null,
        address_2: null,
        city_id: null,
        state_id: null,
        zip: null,
        date_of_birth: null,
        ssn: null
    });


    const formatPhoneNumber = (input) => {
        const numericInput = input.replace(/\D/g, '');
        const formattedNumber =
            numericInput.length > 0
                ? `(${numericInput.slice(0, 3)}) ${numericInput.slice(3, 6)}-${numericInput.slice(6, 10)}`
                : '';
        return formattedNumber;
    };

    useEffect(() => {
        getSelectOptionsOfStateInput();
    }, []);

    useEffect(() => {
        if (body?.state_id) {
            getSelectOptionsOfCityInput();
        }
    }, [body?.state_id]);


    let getSelectOptionsOfStateInput = async () => {
        setIsLoading(true)
        try {
            let response = await axios.get(`${config.baseUrl}/states`);
            setIsLoading(false);
            setStateInputData(response.data.data);
        } catch (error) {
            setIsLoading(false);
            toast.error(
                error.response?.data.message || "Something went wrong."
            )
        }
    }

    let getSelectOptionsOfCityInput = async () => {
        setIsLoading(true)
        try {
            let response = await axios.get(`${config.baseUrl}/cities/${body?.state_id}`);
            setIsLoading(false);
            setCityInputData(response.data.data)
        } catch (error) {
            setIsLoading(false);
            toast.error(
                error.response?.data.message || "Something went wrong."
            )
        }
    }


    const handleChangeSelectState = (selectedValue) => {
        setBody((prev) => ({
            ...prev,
            "state_id": selectedValue
        }));
        setErrors({
            ...errors,
            state_id: null,
        });
    }

    const handleChangeSelectCity = (selectedValue) => {
        setBody((prev) => ({
            ...prev,
            "city_id": selectedValue
        }));
        setErrors({
            ...errors,
            city_id: null,
        });
    }

    const onChangeInput = (e) => {
        const {name, value} = e.target;
        if (name === "phone") {
            const formattedNumber = formatPhoneNumber(value);
            setBody((prevBody) => ({...prevBody, [name]: formattedNumber}));
        } else if (name === "ssn") {
            const rawValue = e.target.value.replace(/-/g, '');
            const formattedValue = rawValue
                .slice(0, 9)
                .replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
            setBody((prevBody) => ({...prevBody, [name]: formattedValue}));
        } else {
            setBody((prevBody) => ({...prevBody, [name]: value}));
        }
        setErrors({
            ...errors,
            [name]: null
        });
    }


    let validateFirstStep=async()=>{
        let formData = new FormData();
        formData.append('step', +1);
        formData.append('suffix',  +body?.suffix_id); //?
        formData.append('first_name', body?.first_name);
        formData.append('middle_name', body?.middle_name);
        formData.append('last_name', body?.last_name);
        formData.append('email', body?.email);
        formData.append('phone', + body?.phone.replace(/\D/g, ''));
        formData.append('address_1', body?.address_1);
        formData.append('address_2', body?.address_2);
        formData.append('state_id', +body?.state_id);
        formData.append('city_id',  +body?.city_id);
        formData.append('zip', + body?.zip);
        formData.append('date_of_birth',  body?.date_of_birth);
        formData.append('ssn', + body?.ssn.replace(/\D/g, ''));
        setIsLoading(true);
        try {
            let response = await axios.post(`${config.baseUrl}/validate-steps`, formData);
            setIsLoading(false);
                    toast.success("Checked this step successfully.");
                    setTimeout(()=>{
                             navigate('/aid-application/long-term/2', {state: body})},
                        4000)
        }
        catch (e) {
            setIsLoading(false);
            toast.error(
                e.response?.data.message || "Something went wrong."
            )
        }
    }

    const submitHandler = e => {
        e.preventDefault();
        const {first_name,middle_name, last_name, email, phone, address_1, city_id, state_id, zip, date_of_birth, ssn} = body;
        let valid = true;
        let first_name_message = null;
        let middle_name_message =null;
        let last_name_message = null;
        let email_message = null;
        let phone_message = null;
        let address_1_message = null;
        let city_id_message = null;
        let state_id_message = null;
        let zip_message = null;
        let date_of_birth_message = null;
        let ssn_message = null;
        if (!first_name) {
            first_name_message = "First name is required.";
            valid = false
        }
        if (!middle_name) {
            middle_name_message = "Middle name is required.";
            valid = false
        }
        if (!last_name) {
            last_name_message = "Last name is required.";
            valid = false
        }
        if (!email) {
            email_message = "Email is required";
            valid = false
        }
        if (email) {
            const emailReg = /\w+(\.|-|_)?\w+@\w+\.\w{2,3}/;
            if (!emailReg.test(email)) {
                email_message = "Invalid email";
                valid = false
            }
        }
        if (!phone) {
            phone_message = "Phone is required";
            valid = false
        }
        if (!address_1) {
            address_1_message = "Street address is required."
            valid = false
        }
        if (!city_id) {
            city_id_message = "City is required."
            valid = false
        }
        if (!state_id) {
            state_id_message = "State is required."
            valid = false
        }
        if (!zip) {
            zip_message = "Zip code is required."
            valid = false
        }
        if (!date_of_birth) {
            date_of_birth_message = "Date of birth is required."
            valid = false
        }
        if (!ssn) {
            ssn_message = "Social security number is required."
            valid = false
        }
        setErrors({
            first_name: first_name_message,
            middle_name: middle_name_message,
            last_name: last_name_message,
            email: email_message,
            phone: phone_message,
            address_1: address_1_message,
            city_id: city_id_message,
            state_id: state_id_message,
            zip: zip_message,
            date_of_birth: date_of_birth_message,
            ssn: ssn_message
        });
        if (valid) {
            validateFirstStep();
        }
    }

    if (isLoading) return <Loader/>;

    return (
        <div className={classes.longWhole}>
            <div className={classes.longTitle}>Based on the information you provided, you would qualify for assistance
                paying your medical debt. Please complete our application for immediate consideration.
            </div>
            <form className={classes.formWhole} onSubmit={submitHandler}>
                <div className={classes.formDiv}>
                    <div className={classes.unit}>
                        <Input
                            maxWidth={"209px"}
                            width={"100%"}
                            hasError={errors.first_name}
                            errorText={errors.first_name}
                            input={{
                                maxWidth: "209px",
                                width: "100%",
                                placeholder: "First Name *",
                                value: body?.first_name,
                                type: "text",
                                onChange: onChangeInput,
                                name: "first_name",
                            }}
                        />
                        <Input
                            maxWidth={"209px"}
                            width={"100%"}
                            hasError={errors.middle_name}
                            errorText={errors.middle_name}
                            input={{
                                maxWidth: "209px",
                                width: "100%",
                                placeholder: "Middle Name *",
                                value: body?.middle_name,
                                type: "text",
                                onChange: onChangeInput,
                                name: "middle_name",
                            }}
                        />
                        <Input
                            maxWidth={"409px"}
                            width={"100%"}
                            hasError={errors.last_name}
                            errorText={errors.last_name}
                            input={{
                                maxWidth: "409px",
                                width: "100%",
                                placeholder: "Last Name *",
                                value: body?.last_name,
                                type: "text",
                                onChange: onChangeInput,
                                name: "last_name",
                            }}
                        />
                        <div className={classes.control}>
                            <select
                                className={`${body?.suffix_id === null ? classes.selectStyle : classes.selectPlaceholderStyle}`}
                                name="suffix_id" id="suffix_id" onChange={onChangeInput}>
                                <option className={classes.optionDisabled} value="suffix" disabled selected>Suffix
                                </option>
                                <option value="0">Jr</option>
                                <option value="1">Sr</option>
                                <option value="2">III</option>
                            </select>
                        </div>
                    </div>
                    <div className={classes.unit}>
                        <Input
                            hasError={errors.email}
                            errorText={errors.email}
                            input={{
                                maxWidth: "409px",
                                width: "100%",
                                placeholder: "Email *",
                                value: body?.email,
                                type: "email",
                                onChange: onChangeInput,
                                name: "email",
                            }}
                        />
                        <Input
                            hasError={errors.phone}
                            errorText={errors.phone}
                            input={{
                                maxWidth: "409px",
                                width: "100%",
                                placeholder: "(XXX) XXX-XXXX *",
                                value: body?.phone,
                                type: "text",
                                onChange: onChangeInput,
                                name: "phone",
                            }}
                        />
                    </div>
                    <div className={classes.unit}>
                        <Input
                            hasError={errors.address_1}
                            errorText={errors.address_1}
                            input={{
                                maxWidth: "409px",
                                width: "100%",
                                placeholder: "Street Address 1 *",
                                value: body?.address_1,
                                type: "text",
                                onChange: onChangeInput,
                                name: "address_1",
                            }}
                        />
                        <Input
                            hasError={errors.address_2}
                            errorText={errors.address_2}
                            input={{
                                maxWidth: "409px",
                                width: "100%",
                                placeholder: "Street Address 2",
                                value: body?.address_2,
                                type: "text",
                                onChange: onChangeInput,
                                name: "address_2",
                            }}
                        />
                    </div>
                    <div className={classes.unit}>
                        {errors.state_id ?
                            <div className={classes.selectSearchError}>
                                <SelectSearch
                                    placeholder="State *"
                                    options={stateInputData}
                                    name='state_id'
                                    value={body?.state_id}
                                    search
                                    onChange={handleChangeSelectState}
                                />
                                <div className={classes.errorText}>{errors.state_id}</div>
                            </div> :
                            <SelectSearch
                                placeholder="State *"
                                options={stateInputData}
                                name='state_id'
                                value={body?.state_id}
                                search
                                onChange={handleChangeSelectState}
                            />
                        }
                        {errors.city_id ?
                            <div className={classes.selectSearchError}>
                                <SelectSearch
                                    placeholder="City *"
                                    options={cityInputData}
                                    name='city_id'
                                    value={body?.city_id}
                                    search
                                    onChange={handleChangeSelectCity}
                                />
                                <div className={classes.errorText}>{errors.city_id}</div>
                            </div> :
                            <SelectSearch
                                placeholder="City *"
                                options={cityInputData}
                                name='city_id'
                                value={body?.city_id}
                                search
                                onChange={handleChangeSelectCity}
                            />
                        }
                        <Input
                            hasError={errors.zip}
                            errorText={errors.zip}
                            input={{
                                maxWidth: "122px",
                                width: "100%",
                                placeholder: "Zip *",
                                value: body?.zip,
                                type: "number",
                                onChange: onChangeInput,
                                name: "zip",
                            }}
                        />
                    </div>
                    <div className={classes.unit}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateField
                                value={body?.date_of_birth ? moment(body?.date_of_birth) : null}
                                onChange={(newValue, e) => {
                                    if (e.validationError !== "invalidDate") {
                                        // onChangeInput({target:{value:moment(newValue).format("MM/DD/YYYY"), name:"date_of_birth"}})
                                        onChangeInput({target:{value:moment(newValue).format("YYYY-MM-DD"), name:"date_of_birth"}})
                                        }
                                        else if(e.validationError === "invalidDate"){
                                            onChangeInput({target:{value:null, name:"date_of_birth"}})
                                        }
                                    }}
                                slotProps={{
                                    textField: {
                                        helperText: errors.date_of_birth,
                                    },
                                }}
                                className={`${errors?.date_of_birth  ? "err" : "dateField"}`}
                                disableFuture
                                placeholder="Date of Birth (MM/DD/YYYY) *"/>
                            </LocalizationProvider>
                        <Input
                            hasError={errors.ssn}
                            errorText={errors.ssn}
                            input={{
                                maxWidth: "409px",
                                width: "100%",
                                placeholder: "Social security number",
                                value: body?.ssn,
                                type: "text",
                                onChange: onChangeInput,
                                name: "ssn",
                            }}
                        />
                    </div>
                </div>
                <button className={classes.next}>Next</button>
            </form>
        </div>
    )
}

export default ApplicationLongTermFinal1;