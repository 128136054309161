import React from "react";
import Logo from '../../assets/images/Logo.svg';
import {useNavigate} from "react-router-dom";
import classes from './Header.module.css';

function Header(){

    const headerData=[
        {
            title:"About Us",
            href:"about-us"
        },
        {
            title:"Donate",
            href:"donate"
        },
        {
            title:"Aid Application",
            href:"aid-application/short-term"
        },
        {
            title:"Providers",
            href:"providers"
        },
        {
            title:"Programs",
            href:"programs"
        },
        {
            title:"Volunteer",
            href:"volunteer"
        },
        {
            title:"FAQs",
            href:"faqs"
        }
    ]

    const navigate=useNavigate();

    const navigateToPage = (val) => {
        navigate(`/${val}`);
    }


    let renderHeaderData = headerData.map((item, index)=>(
        <div  key={item.href}
              className={`${window.location.pathname === `/${item.href}` ? classes.activeItem:classes.item}`}
              onClick={()=>navigateToPage (item.href)}>{item.title}</div>
    ))

    return(
        <div className={classes.whole}>
            <div className={classes.left}>
                <div className={classes.logo} onClick={()=>{navigate('/home')}}><img src={Logo} alt={""}/></div>
                <div className={classes.itemsWhole}>
                    {renderHeaderData}
                </div>
            </div>
            <div className={classes.right}/>
        </div>
    )
}

export default Header;