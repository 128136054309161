import React, {useEffect} from "react";
import classes from './Providers.module.css';
import Providers1 from "../../components/ProvidersComponents/Providers1/Providers1";
import Header from "../../components/Header/Header";
import Providers2 from "../../components/ProvidersComponents/Providers2/Providers2";
import Footer from "../../components/Footer/Footer";
import {useLocation} from "react-router-dom";



function Providers(){

    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        onTop();
    }, [routePath]);

    return(
        <>
            <div className={classes.whole}>
                <Header/>
            </div>
            <Providers1/>
            <Providers2/>
            <Footer/>
        </>
    )
}

export default Providers;