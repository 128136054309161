import React from "react";
import Modal from 'react-modal';
import ClosingIcon from '../../assets/icons/ClosingIcon.svg';
import classes from './ApplicationModal.module.css';


function ApplicationModal(props){


    const customStyles = {
        content: {
            paddingTop: '38px',
            paddingRight: '38px',
            maxWidth: '1232px',
            width: '100%',
            top: '55%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            background:'#ECF4F9',
            borderRadius:'22px',
            border:"none"
        },
        overlay: {zIndex: 1000}
    };

    return(
        <>
            <Modal
                isOpen={props.applicationModalIsOpen}
                onRequestClose={props.closeApplicationModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className={classes.modalInside}>
                    <div className={classes.closingDiv}
                    onClick={()=>props.closeApplicationModal()}>
                        <img className={classes.closingIcon} src={ClosingIcon} alt={""}/>
                    </div>
                    <div className={classes.modalText}>{props.text}</div>
                </div>
            </Modal>
            </>
    )
}


export default ApplicationModal;