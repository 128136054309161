import React from "react";
import classes from './Providers2.module.css';



function Providers2(){


    return(
        <div className={classes.whole}>
            <div className={"container"}>
                <div className={classes.text}>Let us show you what impact our partnership can have on your organization.
                    It is a win-win situation for patients and providers alike. Contact us today to initiate a
                    discussion on how we can work together to bring relief to you and your patients. Email us at:
                    <span className={classes.info}>
                      providers@cleanslatez.org
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Providers2;