import React from "react";

const datePattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/\d{4}$/;
const regExpName = /([A-Z][a-z]*)([\\s\\\'-][A-Z][a-z]*)*/;
const regExpEmail = /\w+(\.|-|_)?\w+@\w+\.\w{2,3}/ ;
const regExpPass =  /^[A-Za-z\d@$!%*?&.]{8,}$/;
const isName = value => regExpName.test(value);
const isEmail = value => regExpEmail.test(value);
const isPassword = value => regExpPass.test(value);
const isNotEmpty = value => value.trim() !== '';
const isDate=value =>datePattern.test(value);


const useValidation = () => {

    return {
        isName,
        isEmail,
        isPassword,
        isNotEmpty,
        isDate
    }
}

export default useValidation;