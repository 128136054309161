import React from "react";
import Image24 from '../../../assets/images/Image24.png';
import classes from './Home2.module.css';


function Home2(){

    const effects=[{
        title:"Health Decline",
        text:"- People saddled with medical debt often face secondary health challenges, from high blood pressure to " +
            "mental health strain. Their self-reported health status dwindles, life expectancy shrinks, and the" +
            " shadows of financial strain impact well-being."
    },
        {
            title:"Credit Catastrophe ",
            text:" - It's not just an unpaid bill. A missed medical payment can lead to a dent in credit history. After " +
                "180 days, it morphs into an \"account in collections,\" affecting everything from housing applications" +
                " to employment prospects."
        },
        {
            title:"Barrier to Better Debt",
            text:"- A tarnished credit history isn't just a number. It stifles opportunities for mortgages, loans, and" +
                " other financial endeavors that could uplift one's economic mobility and long-term prosperity."
        },
        {
            title:"Job Jeopardy ",
            text:"- Employment isn't just about skills and experience. With over 30% of employers peeking " +
                "into credit histories, an unpaid medical bill might be the silent factor in missed job opportunities " +
                "and promotions."
        },
        {
            title:"Home Hurdles ",
            text:"- Dreaming of a new home? Medical debt could stand in the way. Whether it's securing a mortgage, " +
                "navigating loan terms, or simply convincing a landlord to hand over the keys, a poor credit history " +
                "erects barriers."
        },
        {
            title:"Costly Consequences ",
            text:"- Consider this: a good credit score could save $3,000 in interest on a $10,000 car loan. The true" +
                " cost of medical debt extends far beyond the initial bill, inflating interest rates and financial" +
                " pressures."
        },
        {
            title:"Transit & Utility Troubles ",
            text:"- Beyond homes and jobs, even basic needs get complicated. Car loans become pricier, and setting up " +
                "utilities might demand heftier security deposits. Every aspect of daily life feels the pinch."
        },
        {
            title:"Debt's Domino Effect ",
            text:"- It's not just about one bill. Those struggling with medical payments often find themselves in a " +
                "cycle, juggling credit card debts, student loans, mortgages, and even payday loans."
        },
        {
            title: "The Unexpected Victims -",
            text: "Think it's only the financially careless? Think again. Half of those with medical collections on" +
                " their record have an otherwise pristine credit history. Medical debt is an uninvited guest, crashing " +
                "even the most financially disciplined lives."
        }
    ]

    const renderEffects=effects.map((item, index)=>(
        <div className={classes.titleEffect} key={index}>{item.title}
            <span className={classes.textEffect}>{item.text}</span> </div>

    ))


    return(
        <div className={"container"}>
            <div className={classes.content}>
                <div className={classes.title}>Medical Debt Causes Serious Problems - Sometimes for Years</div>
                <div className={classes.subTitle}>Take a deep dive into the cascading effects of medical debt, an
                    often-unseen crisis. This isn't just about bills—it's about lives, livelihoods, and long-term health
                    repercussions.</div>
                <div className={classes.whole}>
                    {renderEffects}
                </div>
                <div className={classes.imgDiv}><img src={Image24}/></div>
            </div>
        </div>
    )
}

export default Home2;