import React, {useState} from "react";
import classes from './ApplicationLongTermFinal2.module.css';
import Input from "../../../ui/Input/Input";
import Plus from "../../../assets/icons/Plus.svg";
import {useLocation, useNavigate} from "react-router-dom";
import Loader from "../../../ui/Loader/Loader";


function ApplicationLongTermFinal2(){

    const {state} = useLocation();
    const secondPartState=state;
    const navigate=useNavigate();
    const [isLoading, setIsLoading] = useState(false);


    const [body, setBody] = useState({
        creditor_name:null,
        creditorStreetAddress1:null,
        creditorStreetAddress2:null,
        creditorCity:null,
        creditorState:null,
        creditorZip:null,
        creditorTelephoneNumber:null,
        dateOfAccount:null,
        outstandingBalance:null,
        providerName:null,
        providerTelephone:null,
        providerStreetAddress1:null,
        providerStreetAddress2:null,
        providerCity:null,
        providerState:null,
        providerZip:null
    });

    const [errors, setErrors] = useState({
        creditor_name:null,
        creditorStreetAddress1:null,
        creditorStreetAddress2:null,
        creditorCity:null,
        creditorState:null,
        creditorZip:null,
        creditorTelephoneNumber:null,
        dateOfAccount:null,
        outstandingBalance:null,
        providerName:null,
        providerTelephone:null,
        providerStreetAddress1:null,
        providerStreetAddress2:null,
        providerCity:null,
        providerState:null,
        providerZip:null
    });

    const formatPhoneNumber = (input) => {
        const numericInput = input.replace(/\D/g, '');
        const formattedNumber =
            numericInput.length > 0
                ? `(${numericInput.slice(0, 3)}) ${numericInput.slice(3, 6)}-${numericInput.slice(6, 10)}`
                : '';

        return formattedNumber;
    };

    const onChangeInput = (e) => {
        const { name, value } = e.target;
        if(name ==="creditorTelephoneNumber" || name==="providerTelephone"){
            const formattedNumber = formatPhoneNumber(value);
            setBody((prevBody) => ({ ...prevBody, [name]: formattedNumber }));
        }
        else{
            setBody((prevBody) => ({ ...prevBody, [name]: value }));
        }
        setErrors({
            ...errors,
            [name]: null
        });
    }





    const submitHandler =   e => {
        e.preventDefault();
        const {creditor_name, creditorStreetAddress1,creditorCity,creditorState,creditorZip,creditorTelephoneNumber,
            dateOfAccount, outstandingBalance, providerName, providerTelephone, providerStreetAddress1, providerCity,
            providerState,providerZip}=body;
        let valid = true;
        let creditor_name_message =null;
        let creditorStreetAddress1_message =null;
        let creditorCity_message =null;
        let creditorState_message =null;
        let creditorZip_message=null;
        let creditorTelephoneNumber_message =null;
        let dateOfAccount_message = null;
        let outstandingBalance_message = null;
        let providerName_message=null;
        let providerTelephone_message=null;
        let providerStreetAddress1_message=null;
        let providerCity_message=null;
        let providerState_message=null;
        let providerZip_message=null;
        if (!creditor_name) {
            creditor_name_message = "Creditor name is required.";
            valid = false
        }
        if (!creditorStreetAddress1) {
            creditorStreetAddress1_message = "Street address 1 is required.";
            valid = false
        }
        if (!creditorCity) {
            creditorCity_message = "Creditor city is required.";
            valid = false
        }
        if (!creditorState) {
            creditorState_message = "Creditor state is required.";
            valid = false
        }
        if(!creditorZip){
            creditorZip_message= "Creditor zip code is required.";
            valid = false
        }
        if(!creditorTelephoneNumber){
            creditorTelephoneNumber_message= "Creditor phone is required.";
            valid = false
        }
        if(!dateOfAccount){
            dateOfAccount_message = "Account date is required.";
            valid = false
        }
        if (dateOfAccount) {
            const datePattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/\d{4}$/;
            if (!datePattern.test(dateOfAccount)) {
                dateOfAccount_message = "Invalid type of date.";
                valid = false
            }
        }
        if(!outstandingBalance){
            outstandingBalance_message= "Outstanding balance is required.";
            valid = false
        }
        if (!providerName){
            providerName_message= "Provider name is required.";
            valid = false
        }
        if (!providerTelephone){
            providerTelephone_message= "Provider phone is required.";
            valid = false
        }
        if(!providerStreetAddress1){
            providerStreetAddress1_message="Provider street address is required.";
            valid = false
        }
        if(!providerCity){
            providerCity_message="Provider city is required.";
            valid = false
        }
        if(!providerState){
            providerState_message="Provider state is required.";
            valid = false
        }
        if(!providerZip){
            providerZip_message="Provider zip is required.";
            valid = false
        }
        setErrors({
            creditor_name:creditor_name_message,
            creditorStreetAddress1:creditorStreetAddress1_message,
            creditorCity:creditorCity_message,
            creditorState:creditorState_message,
            creditorZip:creditorZip_message,
            creditorTelephoneNumber:creditorTelephoneNumber_message,
            dateOfAccount:dateOfAccount_message,
            outstandingBalance:outstandingBalance_message,
            providerName:providerName_message,
            providerTelephone:providerTelephone_message,
            providerStreetAddress1:providerStreetAddress1_message,
            providerCity:providerCity_message,
            providerState:providerState_message,
            providerZip:providerZip_message
        });
        if (valid) {
            navigate('/aid-application/long-term/3', {state:body})
        }
    }

    if (isLoading) return <Loader/>;

    return(
        <div className={classes.longWhole}>
            <div className={classes.longTitle}>Please list all of your delinquent medical debt:</div>
            <form className={classes.formWhole}
                  onSubmit={submitHandler}>
                <div className={classes.formDiv}>
                    <Input
                        hasError={errors.creditor_name}
                        errorText={errors.creditor_name}
                        input={{
                            maxWidth:"409px",
                            width: "100%",
                            placeholder: "Creditor Name *",
                            value: body?.creditor_name,
                            type: "text",
                            onChange:onChangeInput,
                            name:"creditor_name",
                        }}
                    />
                    <div className={classes.unit}>
                        <Input
                            hasError={errors.creditorStreetAddress1}
                            errorText={errors.creditorStreetAddress1}
                            input={{
                                maxWidth:"409px",
                                width: "100%",
                                placeholder: "Street Address 1 *",
                                value: body?.creditorStreetAddress1,
                                type: "text",
                                onChange:onChangeInput,
                                name:"creditorStreetAddress1",
                            }}
                        />
                        <Input
                            input={{
                                maxWidth:"409px",
                                width: "100%",
                                placeholder: "Street Address 2",
                                value: body?.creditorStreetAddress2,
                                type: "text",
                                onChange:onChangeInput,
                                name:"creditorStreetAddress2",
                            }}
                        />
                    </div>
                    <div className={classes.unit}>
                        <Input
                            hasError={errors.creditorCity}
                            errorText={errors.creditorCity}
                            input={{
                                maxWidth:"409px",
                                width: "100%",
                                placeholder: "Creditor City *",
                                value: body?.creditorCity,
                                type: "text",
                                onChange:onChangeInput,
                                name:"creditorCity",
                            }}
                        />
                        <Input
                            hasError={errors.creditorState}
                            errorText={errors.creditorState}
                            input={{
                                maxWidth:"409px",
                                width: "100%",
                                placeholder: "Creditor State *",
                                value: body?.creditorState,
                                type: "text",
                                onChange:onChangeInput,
                                name:"creditorState",
                            }}
                        />
                        <Input
                            hasError={errors.creditorZip}
                            errorText={errors.creditorZip}
                            input={{
                                maxWidth:"409px",
                                width: "100%",
                                placeholder: "Creditor Zip *",
                                value: body?.creditorZip,
                                type: "text",
                                onChange:onChangeInput,
                                name:"creditorZip",
                            }}
                        />
                    </div>
                    <div className={classes.unit}>
                        <Input
                            hasError={errors.creditorTelephoneNumber}
                            errorText={errors.creditorTelephoneNumber}
                            input={{
                                maxWidth:"409px",
                                width: "100%",
                                placeholder: "(XXX) XXX-XXXX *",
                                value: body?.creditorTelephoneNumber,
                                type: "text",
                                onChange:onChangeInput,
                                name:"creditorTelephoneNumber",
                            }}
                        />
                        <Input
                            hasError={errors.dateOfAccount}
                            errorText={errors.dateOfAccount}
                            input={{
                                maxWidth:"409px",
                                width: "100%",
                                placeholder: "Date Of Account (MM/DD/YYYY) *",
                                value: body?.dateOfAccount,
                                type: "text",
                                onChange:onChangeInput,
                                name:"dateOfAccount",
                            }}
                        />
                        <Input
                            hasError={errors.outstandingBalance}
                            errorText={errors.outstandingBalance}
                            input={{
                                maxWidth:"409px",
                                width: "100%",
                                placeholder: "$0.00 *",
                                value: body?.outstandingBalance,
                                type: "text",
                                onChange:onChangeInput,
                                name:"outstandingBalance",
                            }}
                        />
                    </div>
                    <div className={classes.subTitle}>Original Provider/Hospitals Information</div>
                    <Input
                        hasError={errors.providerName}
                        errorText={errors.providerName}
                        input={{
                            maxWidth:"409px",
                            width: "100%",
                            placeholder: "Provider/Hospital Name *",
                            value: body?.providerName,
                            type: "text",
                            onChange:onChangeInput,
                            name:"providerName",
                        }}
                    />
                    <Input
                        hasError={errors.providerTelephone}
                        errorText={errors.providerTelephone}
                        input={{
                            maxWidth:"409px",
                            width: "100%",
                            placeholder: "(XXX) XXX-XXXX *",
                            value: body?.providerTelephone,
                            type: "text",
                            onChange:onChangeInput,
                            name:"providerTelephone",
                        }}
                    />
                    <div className={classes.unit}>
                        <Input
                            hasError={errors.providerStreetAddress1}
                            errorText={errors.providerStreetAddress1}
                            input={{
                                maxWidth:"409px",
                                width: "100%",
                                placeholder: "Street Address 1 *",
                                value: body?.providerStreetAddress1,
                                type: "text",
                                onChange:onChangeInput,
                                name:"providerStreetAddress1",
                            }}
                        />
                        <Input
                            input={{
                                maxWidth:"409px",
                                width: "100%",
                                placeholder: "Street Address 2 *",
                                value: body?.providerStreetAddress2,
                                type: "text",
                                onChange:onChangeInput,
                                name:"providerStreetAddress2",
                            }}
                        />
                    </div>
                    <div className={classes.unit}>
                        <Input
                            hasError={errors.providerCity}
                            errorText={errors.providerCity}
                            input={{
                                maxWidth:"409px",
                                width: "100%",
                                placeholder: "Provider City *",
                                value: body?.providerCity,
                                type: "text",
                                onChange:onChangeInput,
                                name:"providerCity",
                            }}
                        />
                        <Input
                            hasError={errors.providerState}
                            errorText={errors.providerState}
                            input={{
                                maxWidth:"409px",
                                width: "100%",
                                placeholder: "Provider State *",
                                value: body?.providerState,
                                type: "text",
                                onChange:onChangeInput,
                                name:"providerState",
                            }}
                        />
                        <Input
                            hasError={errors.providerZip}
                            errorText={errors.providerZip}
                            input={{
                                maxWidth:"409px",
                                width: "100%",
                                placeholder: "Provider Zip *",
                                value: body?.providerZip,
                                type: "text",
                                onChange:onChangeInput,
                                name:"providerZip",
                            }}
                        />
                    </div>
                </div>
                <div className={classes.rowLeft}>
                    <div className={classes.iconDiv}><img src={Plus} alt={""}/></div>
                    <div className={classes.btnText}>Add Another Medical Bill</div>
                </div>
                <button className={classes.next}>Next</button>
            </form>
        </div>
    )
}

export default ApplicationLongTermFinal2;