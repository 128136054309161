import React from "react";
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import './App.css';
import Home from "./app/pages/Home/Home";
import FAQs from "./app/pages/FAQs/FAQs";
import Donate from "./app/pages/Donate/Donate";
import AboutUs from "./app/pages/AboutUs/AboutUs";
import Programs from "./app/pages/Programs/Programs";
import Providers from "./app/pages/Providers/Providers";
import Volunteer from "./app/pages/Volunteer/Volunteer";
import DonateNow from "./app/pages/DonateNow";
import AidApplication from "./app/pages/AidApplication/AidApplication";
import AidApplicationShort from "./app/pages/AidApplicationShort/AidApplicationShort";
import AidApplicationLong from "./app/pages/AidApplicationLong/AidApplicationLong";
import ApplicationLongTermFinal1
    from "./app/components/AidApplicationComponents/ApplicationLongTermFinal1/ApplicationLongTermFinal1";
import ApplicationLongTermFinal2
    from "./app/components/AidApplicationComponents/ApplicationLongTermFinal2/ApplicationLongTermFinal2";
import ApplicationLongTermFinal3
    from "./app/components/AidApplicationComponents/ApplicationLongTermFinal3/ApplicationLongTermFinal3";
import ApplicationLongTermFinal4
    from "./app/components/AidApplicationComponents/ApplicationLongTermFinal4/ApplicationLongTermFinal4";


function App() {



  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/home" element={<Home/>}/>
              <Route path="/about-us" element={<AboutUs/>}/>
              <Route path="/providers" element={<Providers/>}/>
              <Route path="/donate" element={<Donate/>}/>
              <Route path="/donate-now" element={<DonateNow/>}/>
              <Route path="/programs" element={<Programs/>}/>
              <Route path="/volunteer" element={<Volunteer/>}/>
              <Route path="/faqs" element={<FAQs/>}/>
              <Route path="/aid-application" element={<AidApplication/>}>
                  <Route index path="short-term" element={<AidApplicationShort/>}/>
                  <Route path="long-term" element={<AidApplicationLong/>}>
                      <Route path="1" element={<ApplicationLongTermFinal1/>}/>
                      <Route path="2" element={<ApplicationLongTermFinal2/>}/>
                      <Route path="3" element={<ApplicationLongTermFinal3/>}/>
                      <Route path="4" element={<ApplicationLongTermFinal4/>}/>
                  </Route>
              </Route>
          </Routes>
      </BrowserRouter>
  )
}

export default App;