import React, {useState} from "react";
import classes from './ApplicationLongTermFinal3.module.css';
import useInput from "../../../hooks/useInput";
import useValidation from "../../../hooks/useValidation";
import Input from "../../../ui/Input/Input";
import {useNavigate} from "react-router-dom";



function ApplicationLongTermFinal3(){

    const {isNotEmpty}=useValidation();
    const [showReligiousAffiliationInput, setShowReligiousAffiliationInput]=useState(false);
    const [showEthnicityInput, setShowEthnicityInput]=useState(false);
    const navigate=useNavigate();


    const {
        value: residenceState,
        isValid:residenceStateIsValid,
        hasError: residenceStateHasError,
        valueChangeHandler: residenceStateChangeHandler,
        inputBlurHandler: residenceStateBlurHandler,
        reset: resetResidenceState,
        isTouched:residenceStateIsTouched,
    } = useInput(isNotEmpty);

    const {
        value: numberOfPersons,
        isValid:numberOfPersonsIsValid,
        hasError: numberOfPersonsHasError,
        valueChangeHandler:numberOfPersonsChangeHandler,
        inputBlurHandler: numberOfPersonsBlurHandler,
        reset: resetNumberOfPersons,
        isTouched:numberOfPersonsIsTouched,
    } = useInput(isNotEmpty);

    const {
        value: income,
        isValid:incomeIsValid,
        hasError: incomeHasError,
        valueChangeHandler: incomeChangeHandler,
        inputBlurHandler: incomeBlurHandler,
        reset: resetIncome,
        isTouched:incomeIsTouched,
    } = useInput(isNotEmpty);
    const {
        value: medicalDebt,
        isValid:medicalDebtIsValid,
        hasError: medicalDebtHasError,
        valueChangeHandler:medicalDebtChangeHandler,
        inputBlurHandler: medicalDebtBlurHandler,
        reset: resetMedicalDebt,
        isTouched:medicalDebtIsTouched,
    } = useInput(isNotEmpty);

    const {
        value: code,
        isValid:codeIsValid,
        hasError: codeHasError,
        valueChangeHandler: codeChangeHandler,
        inputBlurHandler: codeBlurHandler,
        reset: resetCode,
        isTouched:codeIsTouched,
    } = useInput(isNotEmpty);


    const submitHandler =   event => {
        event.preventDefault();
        navigate('/aid-application/long-term/4')
    }

    const showCheckboxSelect=(e)=>{
        if(e.target.name ==="religious_affiliation"){
            setShowReligiousAffiliationInput(prev =>!prev)
        }
        if(e.target.name ==="ethnicity"){
            setShowEthnicityInput(prev =>!prev)
        }
    }



    return(
        <div className={classes.longWhole}>
            <div className={classes.longTitle}>The below questions are optional and will not impact your application.
                However, we may be able to more quickly match you with donors if this information is provided.</div>
            <form className={classes.formWhole}
                onSubmit={submitHandler}
            >
                <div className={classes.formDiv}>
                    <div className={classes.formTitle}>Check All That Apply:</div>
                    <div className={classes.checkboxes}>
                        <div className={classes.checkboxRowBelowPart}>
                            <input className={classes.checkboxInput} type="checkbox" id="currently_enrolled_student" name="currently_enrolled_student"
                                   value="currently_enrolled_student"/>
                            <label className={classes.checkboxLabel}
                                   htmlFor="currently_enrolled_student">Currently enrolled student</label>
                        </div>
                        <div className={classes.checkboxRowBelowPart}>
                            <input className={classes.checkboxInput} type="checkbox" id="disabled" name="disabled"
                                   value="disabled"/>
                            <label className={classes.checkboxLabel}
                                   htmlFor="disabled">Disabled</label>
                        </div>
                        <div className={classes.checkboxRowBelowPart}>
                            <input className={classes.checkboxInput} type="checkbox" id="singleParent" name="singleParent"
                                   value="singleParent"/>
                            <label className={classes.checkboxLabel}
                                   htmlFor="singleParent">Single Parent </label>
                        </div>
                        <div className={classes.checkboxRowBelowPart}>
                            <input  className={classes.checkboxInput}   type="checkbox" id="veteran" name="veteran"
                                    value="veteran"/>
                            <label className={classes.checkboxLabel}
                                   htmlFor="veteran">Veteran </label>
                        </div>
                    </div>
                    <div className={classes.checkboxes}>
                        <div className={classes.checkboxRowBelowPart}>
                            <input className={classes.checkboxInput} type="checkbox" id="religious_affiliation"
                                   onChange={(e)=>{showCheckboxSelect(e)}}
                                   name="religious_affiliation"
                            />
                            <label className={classes.checkboxLabel}
                                   htmlFor="religious_affiliation">Religious Affiliation </label>
                        </div>
                        <div className={classes.checkboxRowBelowPart}>
                            <input className={classes.checkboxInput} type="checkbox" id="ethnicity" name="ethnicity"
                                   onChange={(e)=>{showCheckboxSelect(e)}}/>
                            <label className={classes.checkboxLabel}
                                   htmlFor="ethnicity">Ethnicity</label>
                        </div>
                    </div>
                    <div className={classes.formTitle}>Please select:</div>
                    <div className={classes.row}>
                        {showReligiousAffiliationInput &&
                            <div className={classes.control}>
                                <label className={classes.selectLabel}
                                       htmlFor="religion">Religion </label>
                                <select className={classes.selectInputStyle}  id="religion" name="religion">
                                    <option  selected disabled hidden>Choose</option>
                                    <option value="baptist">Baptist</option>
                                    <option value="catholic">Catholic</option>
                                    <option value="christian">Christian</option>
                                    <option value="hindu">Hindu</option>
                                    <option value="islamic">Islamic</option>
                                    <option value="mormon">Mormon</option>
                                    <option value="presbyterian">Presbyterian</option>
                                    <option value="protestant">Protestant</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                        }
                        {showEthnicityInput &&
                            <div className={classes.control}>
                                <label className={classes.selectLabel}
                                       htmlFor="ethnicity_input">Ethnicity </label>
                                <select className={classes.selectInputStyle}  id="ethnicity_input" name="ethnicity_input">
                                    <option  selected disabled hidden>American Indian or Alaska Native</option>
                                    <option value="baptist">Asian</option>
                                    <option value="catholic">Black or African American
                                    </option>
                                    <option value="christian">Hispanic or Latino</option>
                                    <option value="hindu">Native Hawaiian or Other Pacific Islander</option>
                                    <option value="islamic">White or Caucasian</option>
                                    <option value="mormon">Other</option>
                                </select>
                            </div>}
                    </div>
                </div>
                <div className={classes.formDiv}>
                    <div className={classes.formTitle}>Eligibility information you entered</div>
                    <Input
                        input={{
                            maxWidth:"707px",
                            width: "100%",
                            value: residenceState,
                            placeholder: "Your state of residence",
                            type: "text",
                            onChange: residenceStateChangeHandler,
                            onBlur:residenceStateBlurHandler,
                        }}
                    />
                    <Input
                        input={{
                            maxWidth:"707px",
                            width: "100%",
                            value: numberOfPersons,
                            placeholder: "Number of persons in your household",
                            type: "",
                            onChange: numberOfPersonsChangeHandler,
                            onBlur:numberOfPersonsBlurHandler,
                        }}
                    />
                    <Input
                        input={{
                            maxWidth:"707px",
                            width: "100%",
                            value: income,
                            placeholder: "Your household annual income",
                            type: "",
                            onChange: incomeChangeHandler,
                            onBlur:incomeBlurHandler,
                        }}
                    />
                    <Input
                        input={{
                            maxWidth:"707px",
                            width: "100%",
                            value: medicalDebt,
                            placeholder: "Total household medical debt",
                            type: "",
                            onChange: medicalDebtChangeHandler,
                            onBlur:medicalDebtBlurHandler,
                        }}
                    />
                    <div className={classes.formTitle}>If you were provided a referral code, please enter that code.</div>
                    <Input
                        input={{
                            maxWidth:"707px",
                            width: "100%",
                            value: code,
                            placeholder: "Referral Code ",
                            // type: "number",
                            onChange: codeChangeHandler,
                            onBlur: codeBlurHandler,
                        }}
                    />
                </div>
                <button type={"submit"} style={{margin:"auto"}} className={classes.checkBtn}>Next</button>
            </form>
        </div>
    )
}

export default  ApplicationLongTermFinal3;